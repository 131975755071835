

const inputEl = document.getElementById('qrcodeinput');
const container = document.getElementById('qrcodeContainer');
const flexSwitchCheckDefaultEl = document.getElementById('flexSwitchCheckDefault');


function generate(val){
  if(!window.QRCode)
    return console.log('QRCode not');
  console.log('generate', window);
  val = document.getElementById("qrcodeinput_domain").value+val;
//https://www.npmjs.com/package/qrcodejs?utm_source=cdnjs&utm_medium=cdnjs_link&utm_campaign=cdnjs_library
  let sizes = [64, 128, 256, 512, 1024];
  container.innerHTML = '<h1>'+val+'</h1>';
  if(flexSwitchCheckDefaultEl.checked)
    container.classList.add('transparent');
  else
    container.classList.remove('transparent');

  for(let size of sizes){
    let el = document.createElement('div');
    el.classList.add('qrcode_'+size);
    el.style.width = size + 'px';
    el.style.height = size + 'px';
    container.appendChild(el);
    var qrcode = new window.QRCode(el, {
      text: val,
      width: size,
      height: size,
      colorDark : flexSwitchCheckDefaultEl.checked?"#000000":"#ffffff",
      colorLight : !flexSwitchCheckDefaultEl.checked?"#000000":"#ffffff",
      correctLevel : window.QRCode.CorrectLevel.H  /*LMQH */
    });

    el.addEventListener('click', function(){
      //console.log(el.querySelector('img').src);


      downloadImg(el.querySelector('img').src.replace(/^data:image\/[^;]+/, 'data:application/octet-stream'), size+"_"+val+"_"+(flexSwitchCheckDefaultEl.checked?"light":"dark")+".png")
    });

  }

}


const downloadImg = (data, fileName) => {


  var img = document.createElement('img');
  img.src = data;

  var a = document.createElement('a');
  a.setAttribute("download", fileName);
  a.setAttribute("href", data);
  a.appendChild(img);

  //var w = open();
  //w.document.title = 'Export Image';
  //w.document.body.innerHTML = 'Left-click on the image to save it.';
  //w.document.body.appendChild(a);
  a.click();
}

window.generate = generate;


const loaded = (e) => {
  console.log('qrcodes loaded', e);

  generate(inputEl.value);
}
export function init() {


  console.log('qrcodes init');
  var s = document.createElement( 'script' );
  s.setAttribute( 'src', "/js/qrcode.min.js" );
  s.onload=loaded;
  document.head.appendChild( s );

}