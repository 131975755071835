import { init } from "./qrcodes";

const checkHash = ()=>{
    if(document.location.hash === "#qrcodes"){
        init();
        document.body.classList.add('qrcodes');
    }else{
        document.body.classList.remove('qrcodes');
    }
}
checkHash();

window.addEventListener('hashchange', () => {
    console.log('hashchange', document.location.hash);
    checkHash();
});

let apiUrl = "/order.php";
if(document.location.href.indexOf(':1234') > -1) {
    apiUrl = "http://localhost/order.php";
    apiUrl = "https://shop.dua.aktuelleprojekte.de/order.php";
}
console.log('apiUrl', apiUrl);

const apiToken = "12345";

const root = document.getElementById('root');
const ordersEl = document.getElementById('orders');


async function getOrders(doneHandler) {
    document.body.classList.add('loading');
    const response = await fetch(apiUrl, {
    headers: {
        "Content-Type": "application/json",
        "key": apiToken,
    },
    method: "POST",
    body: JSON.stringify({action:"get"}),
    });
    const data = await response.json();
    console.log('getOrders', data);
    setTimeout(() => {
        document.body.classList.remove('loading');
    }, 500);
    if(doneHandler) doneHandler(data);
}

const deleteOrder = async (id) => {
    document.body.classList.add('loading');
    const response = await fetch(apiUrl, {
        headers: {
            "Content-Type": "application/json",
            "key": apiToken,
        },
        method: "POST",
        body: JSON.stringify({action:"delete", id}),
    });
    const data = await response.json();
    console.log('deleteOrder1', data);
    if(data?.orders){
        actualOrders = data.orders;
        showOrders(actualOrders);
    }
    setTimeout(() => {
        document.body.classList.remove('loading');
    }, 500);
}

window.deleteOrder = deleteOrder;



const copyToClipboard = (text) => {
    console.log('copyToClipboard', text);
    navigator.clipboard.writeText(text);
}
window.copyToClipboard = copyToClipboard;


const showOrders = (orders) => {
    const filter = document.getElementById('filter').value;

    ordersEl.innerHTML = '';

    let filteredOrders = orders;
    if(filter) {
        filteredOrders = orders.filter((order) => {
            return order.name.toLowerCase().includes(filter.toLowerCase()) || order.id.toLowerCase().includes(filter.toLowerCase())  || order.public_id.toLowerCase().includes(filter.toLowerCase());
        });
    }

    filteredOrders.forEach((order) => {
        const orderEl = document.createElement('div');
        orderEl.className = 'order';
        let ean = order.ean;
        ean = ean.split(' ').join('');
        let eanInner = ean;
        if(ean.length>0){
            let code = ean.substring(ean.length-2,ean.length);
            ean = ean.substring(0,ean.length-2);

            eanInner = `${ean} <strong title="Parfüm Nr">${code}</strong>`;
        }
        orderEl.innerHTML = `
            <p class="id">${order.public_id}</p>
            <p class="name" title="Name"><i class="bi bi-copy" onclick="copyToClipboard('${order.name}')"></i> ${order.name}</p>
            <p class="ean">${eanInner}</p>
            <p class="size" title="Größe">${order.size.toLowerCase()==="s"?"1.2ml":"30ml"}</p>
            <p class="timestamp">${new Date(order.timestamp*1000).toLocaleTimeString()}</p>
            <p class="station" title="Station ${order.station}">${order.station}</p>
            <div class="actions hidden">
                <div class="btn delete btn-danger" onclick="deleteOrder('${order.id}')">löschen</div>
            </div>
        `;
        ordersEl.appendChild(orderEl);
    });
}

let actualOrders;
const loadedOrders = (data) => {
    actualOrders = data.orders;
    showOrders(actualOrders);
}


getOrders(loadedOrders);


const applyFilter = () => {
    showOrders(actualOrders);
}
window.applyFilter = applyFilter;

const reload = () => {
    getOrders(loadedOrders);
}
window.reload = reload;


let idleTimeout = null;
let lastFetch = 0;
const resetIdle = () => {
    clearTimeout(idleTimeout);
    idleTimeout = setTimeout(() => {

        if(lastFetch + 1000 * 10 < Date.now()) {
            lastFetch = Date.now();
            reload();
        }
        resetIdle();
    }, 1000 * 20);
}

document.addEventListener("mousemove", resetIdle);
document.addEventListener("keypress", resetIdle);
document.addEventListener("click", resetIdle);
document.addEventListener("scroll", resetIdle);
document.addEventListener("touchstart", resetIdle);
resetIdle();